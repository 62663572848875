<template>
  <div class="contain titlesTop">
    <headTitle title="我加入的团队">
      <span @click="$router.push('/teamList')">团队</span>
    </headTitle>
    <van-pull-refresh
      v-model="isLoading"
      style="height: calc(100vh - 45px); overflow-y: auto"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <div style="width: 100%; height: 3vw"></div>
      <div v-if="empty_show" class="empty_box">暂未加入任何团队</div>
      <div v-else>
        <div v-for="(items, index) in dataList" :key="index">
          <div class="team_box">
            <span>{{ items.name }}</span>
            <div @click="openModal(items)">退出团队</div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <van-overlay :show="window_show">
      <div class="wrapper" @click.stop>
        <div class="success_block">
          <p>退出团队</p>
          <span>确定要退出团队吗</span>
          <div>
            <van-button class="_btn" color="#DA5450" round @click="window_show = false"
              >取消</van-button
            >
            <van-button
              class="_btn"
              color="#DA5450"
              round
              plain
              :loading="loadingFlag"
              @click="quitTeam"
              >确认</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Teamapi from "@/api/team";
import { Toast } from "vant";

export default {
  data() {
    return {
      params: {
        memberId: "",
        regionId: "",
      },
      dataList: [],
      window_show: false,
      empty_show: false,
      loadingFlag: false, // 点击确认按钮，按钮加载
      isLoading: false,
    };
  },
  created() {
    if (localStorage.personalInformation) {
      this.params.memberId = JSON.parse(localStorage.personalInformation).id;
    }
  },
  mounted() {
    this.myTeam();
  },
  methods: {
    myTeam() {
      Teamapi.getMyTeam(this.params)
        .then((res) => {
          this.isLoading = false;
          // console.log(res.data.data);
          if (!res.data.count) {
            this.empty_show = true;
          } else {
            this.empty_show = false;
            this.dataList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //打开退出按钮框
    openModal(val) {
      this.params.regionId = val.region_id;
      this.window_show = true;
    },
    // 点击确认按钮，退出团队
    quitTeam(val) {
      this.loadingFlag = true;
      Teamapi.quitMyTeam(this.params)
        .then((res) => {
          // console.log(res);
          if (res.success) {
            Toast("已退出团队");
            this.myTeam();
          }
        })
        .catch((err) => {
          console.log(err);
          Toast("退出错误");
        });
      this.window_show = false;
      this.loadingFlag = false;
    },

    onRefresh() {
      this.myTeam();
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  padding-bottom: 5vw;
  padding-left: 3vw;
  padding-right: 3vw;
}
.empty_box {
  text-align: center;
  margin-top: 10vw;
  font-size: 0.4rem;
}
.team_box {
  padding: 2vw 0 4vw;
  // margin-top: 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: #eeeeee solid 1px;
  span {
    font-size: 0.34rem;
  }
  div {
    background: #1377e2;
    color: #fff;
    padding: 1vw 2vw;
    border-radius: 5px;
  }
}
.wrapper {
  .success_block {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    background: #fff;
    width: 70vw;
    margin: 65% auto 0;
    border-radius: 10px;
    p {
      font-size: 6vw;
      font-weight: bold;
      margin: 5vw 0 3vw;
    }
    span {
      font-size: 4vw;
    }
    div {
      display: flex;
      justify-content: space-around;
      width: 100%;
      align-items: center;
      ._btn {
        width: 22vw;
        height: 10vw;
        margin: 3vw 0 5vw;
      }
    }
  }
}
</style>
